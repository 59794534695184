const model = {
  email: '',
};

const form = [
  {
    name: 'email',
    label: 'Adresse email',
    type: 'email',
    placeholder: 'Votre adresse email',
    required: true,
    component: 'el-input',
    size: 'col-12',
  },
];

const rules = {
  email: [
    {
      type: 'required',
      message: 'Veuillez entrer votre adresse email',
    },
    {
      type: 'email',
      message: 'Veuillez entrer une adresse email valable',
    },
    {
      type: 'domain',
      message: 'Vous n\'avez pas les permissions requises pour vous connecter.'
    }
  ],
};

export { model, form, rules };
