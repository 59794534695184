<template>
  <app-container
    icon="account_circle"
    colWidth="col-11 col-lg-8"
    v-if="!isTextLoading"
    v-loading="isLoading"
  >
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="forgot-password">
        <p
          class="text-start text-dark"
          v-for="step in texts.steps"
          :key="step.number"
        >
          <span class="step-number">{{ step.number }}</span>
          {{ step.description }}
        </p>
        <div class="form-col mt-4">
          <app-form
            :form="form"
            :model="model"
            :rules="rules"
            :button="texts.button"
            @submitted="handleSubmit"
          />
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
import { form, model, rules } from '@/config/forms/auth/forgot_password';
import useTexts from '@/mixins/useTexts';
import Database from '@/utils/services/Database';
import Utilities from '@/utils/services/Utilities';

// TODO:
// send to db
// logout when resetting password

export default {
  name: 'PasswordPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'forgot_password',
      form: [],
      model: {},
      rules: {},
      isLoading: false

    };
  },
  created() {
    this.isLoading = true
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
    this.isLoading = false
  },
  methods: {
    async handleSubmit(data) {
      const { status } = await Database.create('actions', {
        email: data.email,
        type: 'RESET_PASSWORD',
      });

      if (status !== 201)
        return Utilities.showMessage('error', 'reset_password_error');

      this.$router.push({
        name: 'Success',
        params: { path: 'reinitialisation' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  max-width: 25rem;
  margin: 0 auto;
}
.step-number {
  background-color: $light-grey;
  border-radius: 50%;
  padding: 2px 6px;
}
</style>
